<template>
  <div class="mt-0 main_container">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        <router-link to="/decompte/fer/list">
          Décompte >
        </router-link>
        <span>Détails du décompte ></span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Détails du décompte
          </div>
        </div>
      </div>
    </div>
    <div class="row action-row py-2">
      <div class="col-md-7 mx-auto text-center">

        <button type="button"
                class="btn btn-f-blue "
                @click="goBack()">&nbsp; &#x21E6; Retour
        </button>
        <button v-if="decompte.statut_soumettre_a_validation_utilisateur=='peut_valider'&& (decompte.statut!='DEMANDE_INFORMATION'||decompte.statut_final!='VALIDE')"
                type="button"
                class="btn btn-f-blue mx-1"
                data-toggle="modal"
                data-target="#exampleModal1">
          &#9989; {{nom_bouton}}
        </button>

        <!-- <button v-if="decompte.statut_soumettre_a_validation_utilisateur=='peut_valider'&& (decompte.statut!='DEMANDE_INFORMATION'||decompte.statut_final!='VALIDE')"
                type="button"
                class="btn btn-f-blue "
                data-toggle="modal"
                data-target="#exampleModal">
          <span style="color:#cc1f1f;">&#9746;</span> Refuser
        </button> -->
        <button v-if="(userRole=='DT' || userRole=='ADMIN') 
                  && (decompte.type_decompte=='decompte_travaux_fer') 
                  && (decompte.statut=='DEMANDE_INFORMATION')"
                type="button"
                class="btn btn-f-blue ml-1"
                @click="updateDecompte()">
          Modifier
        </button>
        <button v-if="decompte.statut_imputation_utilisateur=='peut_imputer'&& (decompte.statut!='DEMANDE_INFORMATION'||decompte.statut_final!='VALIDE')"
                type="button"
                class="btn btn-f-blue ml-1"
                data-toggle="modal"
                data-target="#imputationModal">
          Imputer
        </button>
        <button v-show="!hider"
                type="button"
                class="btn btn-f-blue ml-1"
                ref="btn-soumission"
                data-toggle="modal"
                data-target="#soumissionModal">
          Soumettre
        </button>
        <button type="button"
                class="btn btn-f-blue ml-1"
                data-toggle="modal"
                data-target="#ajoutModal"
                v-if="(userRole=='DAF' || userRole=='ADMIN') && (restePayer>0) && (decompte.statut_final=='VALIDE')">
          Décaisser
        </button>
        <button class="btn btn-f-blue ml-1"
                @click="generateReport()"
                type="button"> Imprimer fiche d'analyse</button>
        <!-- Pour l'instant les comptes du FER ne peuvent pas supprimer un decompte
        <button v-if=" status_valider !== 'false'"
                type="button"
                class="btn btn-f-blue mx-1"
                data-toggle="modal"
                data-target="#suppressionModal">
          &#10060; Supprimer
        </button>
        -->
      </div>

    </div>
    <div class="row text-center mb-2">
      <div class="col-12">
        <h3 class=" text-uppercase d-content-red"></h3>
      </div>
    </div>
    <!-- affichage decompte travaux -->
    <div v-if="decompte.type_decompte == 'decompte_travaux_ageroute' || decompte.type_decompte == 'decompte_travaux_fer'"
         class="row mt-3">
      <table class="table col-md-10 mx-auto"
             style="font-size: 0.6em;">
        <thead>
          <tr class="bg-black">
            <th>Informations du décompte</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Type du décompte</td>
            <td>{{ assignDecompte.type_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Numéro du décompte</td>
            <td>{{ assignDecompte.numero_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Référence Ordre de service de prorogation de délai</td>
            <td>{{ assignDecompte.reference_ordre_service_prorogation_delai }}</td>
          </tr>
          <tr>
            <td scope="row">Date de service de prorogation de délai </td>
            <td>{{ assignDecompte.date_ordre_service_prorogation_delai }}</td>
          </tr>
          <tr>
            <td scope="row">Référence Ordre de service de modification d'itinéraire</td>
            <td>{{ assignDecompte.reference_ordre_service_modification_itineraire}}</td>
          </tr>
          <tr>
            <td scope="row">Date de l'Ordre de service de modification d'itinéraire </td>
            <td>{{ assignDecompte.date_service_modification_itineraire }}</td>
          </tr>
          <tr>
            <td scope="row">Désignation de l'assurance chantier </td>
            <td>{{ assignDecompte.libelle_assurance_chantier }}</td>
          </tr>
          <tr>
            <td scope="row">Montant de l'assurance chantier </td>
            <td>{{ assignDecompte.montant_assurance_chantier.toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date d'établissement de l'assurance chantier </td>
            <td>{{ assignDecompte.date_assurance_chantier }}</td>
          </tr>
          <tr>
            <td scope="row">Expiration de l'assurance chantier</td>
            <td>{{ assignDecompte.expiration_assurance_chantier_date}}</td>
          </tr>
          <tr>
            <td scope="row">Désignation du cautionnement AD</td>
            <td>{{ assignDecompte.libelle_cautionnement_ad}}</td>
          </tr>
          <tr>
            <td scope="row">Montant du cautionnement AD</td>
            <td>{{ assignDecompte.montant_cautionnement_ad.toLocaleString()}} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date d'établissement du cautionnement AD</td>
            <td>{{ assignDecompte.date_etablissement_cautionnement_ad}}</td>
          </tr>
          <tr>
            <td scope="row">Expiration de la caution AD</td>
            <td v-if="assignDecompte.expiration_caution_ad_date!=null">{{ assignDecompte.expiration_caution_ad_date}}</td>
            <td v-else>{{ assignDecompte.expiration_caution_ad_texte}}</td>
          </tr>
          <tr>
            <td scope="row">Désignation du cautionnement RG</td>
            <td>{{ assignDecompte.libelle_cautionnement_rg}}</td>
          </tr>
          <tr>
            <td scope="row">Montant du cautionnement RG</td>
            <td>{{ assignDecompte.montant_cautionnement_rg.toLocaleString()}} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date d'établissement du cautionnement RG</td>
            <td>{{ assignDecompte.date_etablissement_cautionnement_rg}}</td>
          </tr>
          <tr>
            <td scope="row">Expiration de la caution RG</td>
            <td v-if="assignDecompte.expiration_caution_rg_date!=null">{{ assignDecompte.expiration_caution_rg_date}}</td>
            <td v-else>{{ assignDecompte.expiration_caution_rg_texte}}</td>
          </tr>
          <tr>
            <td scope="row">Désignation du cautionnement définitif</td>
            <td>{{ assignDecompte.libelle_cautionnement_definitif}}</td>
          </tr>
          <tr>
            <td scope="row">Montant du cautionnement définitif</td>
            <td>{{ assignDecompte.montant_cautionnement_definitif.toLocaleString()}} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date d'établissement du cautionnement définitif</td>
            <td>{{ assignDecompte.date_etablissement_cautionnement_definitif}}</td>
          </tr>
          <tr>
            <td scope="row">Expiration de la caution définitif</td>
            <td v-if="assignDecompte.expiration_cautionnement_definitif_date!=null">{{ assignDecompte.expiration_cautionnement_definitif_date}}</td>
            <td v-else>{{ assignDecompte.expiration_cautionnement_definitif_texte}}</td>
          </tr>
          <tr>
            <td scope="row">Référence de la facture</td>
            <td>{{ assignDecompte.reference_facture }}</td>
          </tr>
          <tr>
            <td scope="row">Montant de la facture</td>
            <td>{{ Math.round(Number(assignDecompte.montant_decompte)).toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date de la facture</td>
            <td>{{ assignDecompte.date_facture }}</td>
          </tr>
          <tr>
            <td scope="row">Taux d'exécution physique</td>
            <td>{{ assignDecompte.taux_execution_physique }} %</td>
          </tr>
          <tr>
            <td scope="row">Date de visa de l'attachement</td>
            <td>{{ assignDecompte.date_visa_attachement }}</td>
          </tr>
          <tr>
            <td scope="row">Date de l'établissement de l'attachement</td>
            <td>{{ assignDecompte.date_attachement_constat_travaux }}</td>
          </tr>
          <tr>
            <td scope="row">Date de validation de l'attachement</td>
            <td>{{ assignDecompte.date_validation_attachement }}</td>
          </tr>
          <tr>
            <td scope="row">Montant du décompte</td>
            <td>{{ Math.round(assignDecompte.montant_facture_decompte).toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date du décompte</td>
            <td>{{ assignDecompte.date_dressage_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Date de validation du DR</td>
            <td>{{ assignDecompte.date_visa_dr_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Date de validation par le DMC</td>
            <td>{{ assignDecompte.date_visa_dmc_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Date de validation par le DGA</td>
            <td>{{ assignDecompte.date_visa_dga_decompte }}</td>
          </tr>
          <!-- <tr>
            <td scope="row">Date de fin des travaux</td>
            <td>{{ assignDecompte.date_fin_travaux }}</td>
          </tr> -->
          <tr>
            <td scope="row">Date d'établissement du procès verbal de reception des travaux</td>
            <td>{{ assignDecompte.date_etablissement_proces_verbal_reception_travaux }}</td>
          </tr>
          <tr>
            <td scope="row">Référence du certificat de service fait</td>
            <td>{{ assignDecompte.reference_certificat_service_fait }}</td>
          </tr>
          <tr>
            <td scope="row">Date d'établissement du certificat de service fait</td>
            <td>{{ assignDecompte.date_certificat_service_fait }}</td>
          </tr>
          <tr>
            <td scope="row">Montant décaissé</td>
            <td>{{ (Math.round(assignDecompte.montant_facture_decompte) - Math.round(restePayer)).toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Reste du montant à décaisser</td>
            <td>{{ Math.round(restePayer).toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Status</td>
            <td>
              <span v-if="assignDecompte.statut_final === 'EN_ATTENTE'"
                    class="badge badge-pill badge-warning">
                En attente
              </span>
              <span v-if="assignDecompte.statut_final === 'VALIDE'"
                    class="badge badge-pill badge-success">
                Validé
              </span>
              <span v-if="assignDecompte.statut_final === 'DEMANDE_INFORMATION'"
                    class="badge badge-pill badge-danger">
                Rejeté
              </span>
              <button v-if="viewTabCommentaire === 'false'"
                      class="ml-2 btn btn-link"
                      type="button"
                      @click="displayTabCommentaire()">Voir les commentaires</button>
              <button v-if="viewTabCommentaire === 'true'"
                      class="ml-2 btn btn-link"
                      type="button"
                      @click="displayNoneTabCommentaire()">Cacher les commentaires</button>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th>Source du décompte</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Programme</td>
            <td>{{ marche.activite.rubrique.programme.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Rubrique</td>
            <td>{{ marche.activite.rubrique.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Activité</td>
            <td>{{ assignDecompte.activite.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Marché</td>
            <td>{{ marche.numero_marche }}</td>
          </tr>
          <tr>
            <td scope="row">Département</td>
            <td>{{ marche.departement }}</td>
          </tr>
          <tr>
            <td scope="row">Lot</td>
            <td>{{ marche.lot }}</td>
          </tr>
          <tr>
            <td scope="row">Date du démarrage effectif</td>
            <td>{{ marche.date_demarrage_effectif }}</td>
          </tr>
          <tr>
            <td scope="row">Date de fin des travaux</td>
            <td>{{ marche.date_fin_travaux }}</td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th style="width:40%">Fichier à télécharger</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Caution d'avance de démarrage</td>
            <td>
              <a :href="assignDecompte.caution_avance_demarrage"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Cautionnement définitif</td>
            <td>
              <a :href="assignDecompte.cautionnement_definitif"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Facture</td>
            <td>
              <a :href="assignDecompte.facture"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Situation financière du marché</td>
            <td>
              <a :href="assignDecompte.situation_financiere_marche"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Retenue de garantie</td>
            <td>
              <a :href="assignDecompte.retenue_garantie"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Attachement</td>
            <td>
              <a :href="assignDecompte.attachement"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Décompte</td>
            <td>
              <a :href="assignDecompte.decompte"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Procès verbal de réception des travaux</td>
            <td>
              <a :href="assignDecompte.proces_verbal_reception_travaux"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Entreprise</td>
            <td>
              <a :href="assignDecompte.entreprise"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Ordre de service de démarrage</td>
            <td>
              <a :href="assignDecompte.ordre_service_demarrage"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Ordre de service d'arrêt</td>
            <td>
              <a :href="assignDecompte.ordre_service_arret"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Ordre de service de reprise des travaux</td>
            <td>
              <a :href="assignDecompte.ordre_service_reprise_travaux"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Ordre de service de prorogation de délai</td>
            <td>
              <a :href="assignDecompte.ordre_service_reprise_prorogation_delai"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Ordre de service de modification d'itinéraire</td>
            <td>
              <a :href="assignDecompte.ordre_service_modification_itineraire"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Assurances chantier</td>
            <td>
              <a :href="assignDecompte.assurance_chantier"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <!-- Fin decompte travaux -->
    <!-- affichage decompte prestation -->
    <div v-else-if="decompte.type_decompte== 'decompte_intellectuel_ageroute'||decompte.type_decompte== 'decompte_intellectuel_dgir'"
         class="row mt-3">
      <table class="table col-md-10 mx-auto"
             style="font-size: 0.6em;">
        <thead>
          <tr class="bg-black">
            <th>Informations du décompte</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Type du décompte</td>
            <td>{{ decompte.type_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Numéro du décompte</td>
            <td>{{ decompte.numero_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Objet des travaux</td>
            <td>{{ marche.objet }}</td>
          </tr>
          <tr>
            <td scope="row">Référence de la facture</td>
            <td>{{ decompte.reference_facture }}</td>
          </tr>
          <tr>
            <td scope="row">Référence du certificat de service fait</td>
            <td>{{ decompte.reference_certificat_service_fait }}</td>
          </tr>
          <tr>
            <td scope="row">Référence du certificat de paiement</td>
            <td>{{ decompte.reference_certificat_paiement }}</td>
          </tr>
          <tr>
            <td scope="row">Référence du courrier d'appel de fond</td>
            <td>{{ decompte.reference_courrier_appel_fonds }}</td>
          </tr>
          <tr>
            <td scope="row">Référence du courrier de demande de proposition de prix</td>
            <td>{{ decompte.reference_courrier_demande_proposition_prix }}</td>
          </tr>
          <tr>
            <td scope="row">Référence du courrier d'indication de la ligne supportant les dépenses</td>
            <td>{{ decompte.reference_courrier_indication_ligne_supportant_depense }}</td>
          </tr>
          <tr>
            <td scope="row">Référence du courrier d'invitation à une séance de négociation</td>
            <td>{{ decompte.reference_courrier_invitation_seance_negociation }}</td>
          </tr>
          <tr>
            <td scope="row">Référence de la demande de cotation</td>
            <td>{{ decompte.reference_demande_cotation }}</td>
          </tr>
          <tr>
            <td scope="row">date d'établissement du certificat de service fait</td>
            <td>{{ decompte.date_etablissement_certificat_service_fait }}</td>
          </tr>
          <tr>
            <td scope="row">date d'établissement du certificat de paiement</td>
            <td>{{ decompte.date_etablissement_certificat_pour_paiement }}</td>
          </tr>
          <tr>
            <td scope="row">date d'établissement du courrier d'appel de fond</td>
            <td>{{ decompte.date_etablissement_courrier_appel_fonds }}</td>
          </tr>
          <tr>
            <td scope="row">date d'établissement du courrier de demande de proposition de prix</td>
            <td>{{ decompte.date_etablissement_courrier_demande_proposition_prix }}</td>
          </tr>
          <tr>
            <td scope="row">date d'établissement du courrier d'indication de la ligne supportant les dépenses</td>
            <td>{{ decompte.date_etablissement_courrier_indication_ligne_supportant_depense }}</td>
          </tr>
          <tr>
            <td scope="row">date d'établissement du courrier d'invitation à une séance de négociation</td>
            <td>{{ decompte.date_etablissement_courrier_invitation_seance_negociation }}</td>
          </tr>
          <tr>
            <td scope="row">date d'établissement de la feuille de temps</td>
            <td>{{ decompte.date_etablissement_feuille_temps }}</td>
          </tr>
          <tr>
            <td scope="row">Montant du décompte</td>
            <td>{{ Math.round(decompte.montant_facture_decompte).toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date décompte</td>
            <td>{{ decompte.date_facture }}</td>
          </tr>
          <tr>
            <td scope="row">Montant de la facture</td>
            <td>{{ Math.round(decompte.montant_decompte).toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date facture</td>
            <td>{{ decompte.date_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Date d'approbation</td>
            <td>{{ decompte.date_approbation }}</td>
          </tr>
          <tr>
            <td scope="row">Date de début d'exécution</td>
            <td>{{ decompte.date_debut_execution }}</td>
          </tr>
          <tr>
            <td scope="row">Date de fin d'exécution</td>
            <td>{{ decompte.date_fin_execution }}</td>
          </tr>
          <tr>
            <td scope="row">Status</td>
            <td>
              <span v-if="decompte.statut_final === 'EN_ATTENTE'"
                    class="badge badge-pill badge-warning">
                En attente
              </span>
              <span v-if="decompte.statut_final === 'VALIDE'"
                    class="badge badge-pill badge-success">
                Validé
              </span>
              <span v-if="decompte.statut_final === 'DEMANDE_INFORMATION'"
                    class="badge badge-pill badge-danger">
                Rejeté
              </span>
              <button v-if="viewTabCommentaire === 'false'"
                      class="ml-2 btn btn-link"
                      type="button"
                      @click="displayTabCommentaire()">Voir les commentaires</button>
              <button v-if="viewTabCommentaire === 'true'"
                      class="ml-2 btn btn-link"
                      type="button"
                      @click="displayNoneTabCommentaire()">Cacher les commentaires</button>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th>Source du décompte</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Programme</td>
            <td>{{ marche.activite.rubrique.programme.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Rubrique</td>
            <td>{{ marche.activite.rubrique.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Activité</td>
            <td>{{ decompte.activite.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Marché</td>
            <td>{{ marche.numero_marche }}</td>
          </tr>
          <tr>
            <td scope="row">Département</td>
            <td>{{ marche.departement }}</td>
          </tr>
          <tr>
            <td scope="row">Lot</td>
            <td>{{ marche.lot }}</td>
          </tr>
          <tr>
            <td scope="row">Date du démarrage effectif</td>
            <td>{{ marche.date_demarrage_effectif }}</td>
          </tr>
          <tr>
            <td scope="row">Date de fin des travaux</td>
            <td>{{ marche.date_fin_travaux }}</td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th style="width:40%">Fichier à télécharger</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Facture</td>
            <td>
              <a :href="decompte.facture"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Certificat de service fait</td>
            <td>
              <a :href="decompte.certificat_service_fait"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Attachement</td>
            <td>
              <a :href="decompte.attachement"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Décompte</td>
            <td>
              <a :href="decompte.decompte"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Rapport</td>
            <td>
              <a :href="decompte.rapport"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">courrier de confirmation deprix</td>
            <td>
              <a :href="decompte.courrier_confirmation_prix"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Courrier d'indication de ligne supportant la dépense</td>
            <td>
              <a :href="decompte.courrier_indication_ligne_lcd"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Courrier d'invitation à la séance de négociation</td>
            <td>
              <a :href="decompte.courrier_invitation_sn"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Feuille de temps</td>
            <td>
              <a :href="decompte.feuille_temps"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Formulaire de demande de cotation</td>
            <td>
              <a :href="decompte.formulaire_demande_cotation"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Formulaire de sélection</td>
            <td>
              <a :href="decompte.formulaire_selection"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Fin affichage decompte prestation -->
    <!-- affichage decompte DGIR -->
    <div v-else
         class="row mt-3">
      <table class="table col-md-10 mx-auto"
             style="font-size: 0.6em;">
        <thead>
          <tr class="bg-black">
            <th>Informations du décompte</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Type du décompte</td>
            <td>{{ decompte.type_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Numéro du décompte</td>
            <td>{{ decompte.numero_decompte }}</td>
          </tr>
          <tr>
            <td scope="row">Objet des travaux</td>
            <td>{{ marche.objet }}</td>
          </tr>
          <tr>
            <td scope="row">Référence de la facture</td>
            <td>{{ decompte.reference_facture }}</td>
          </tr>
          <tr>
            <td scope="row">Montant du décompte</td>
            <td>{{ Math.round(decompte.montant_facture_decompte).toLocaleString() }} FCFA</td>
          </tr>
          <tr>
            <td scope="row">Date facture</td>
            <td>{{ decompte.date_facture }}</td>
          </tr>
          <tr>
            <td scope="row">Date d'approbation</td>
            <td>{{ decompte.date_approbation }}</td>
          </tr>
          <tr>
            <td scope="row">Date de début d'exécution</td>
            <td>{{ decompte.date_debut_execution }}</td>
          </tr>
          <tr>
            <td scope="row">Date de fin d'exécution</td>
            <td>{{ decompte.date_fin_execution }}</td>
          </tr>
          <tr>
            <td scope="row">Date du démarrage effectif</td>
            <td>{{ decompte.date_demarrage_effectif }}</td>
          </tr>
          <tr>
            <td scope="row">Status</td>
            <td>
              <span v-if="decompte.statut_final === 'EN_ATTENTE'"
                    class="badge badge-pill badge-warning">
                En attente
              </span>
              <span v-if="decompte.statut_final === 'VALIDE'"
                    class="badge badge-pill badge-success">
                Validé
              </span>
              <span v-if="decompte.statut_final === 'DEMANDE_INFORMATION'"
                    class="badge badge-pill badge-danger">
                Rejeté
              </span>
              <button v-if="viewTabCommentaire === 'false'"
                      class="ml-2 btn btn-link"
                      type="button"
                      @click="displayTabCommentaire()">Voir les commentaires</button>
              <button v-if="viewTabCommentaire === 'true'"
                      class="ml-2 btn btn-link"
                      type="button"
                      @click="displayNoneTabCommentaire()">Cacher les commentaires</button>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th>Source du décompte</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Activité</td>
            <td>{{ decompte.activite.libelle }}</td>
          </tr>
          <tr>
            <td scope="row">Marché</td>
            <td>{{ decompte.marche.libelle }}</td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-black">
            <th style="width:40%">Fichier à télécharger</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Bon de livraison</td>
            <td>
              <a :href="decompte.bon_livraison"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Certificat de service fait</td>
            <td>
              <a :href="decompte.certificat_service_fait"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Facture</td>
            <td>
              <a :href="decompte.facture"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Situation financière du marché</td>
            <td>
              <a :href="decompte.situation_financiere_marche"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Courrier de confirmation de prix</td>
            <td>
              <a :href="decompte.courrier_confirmation_prix"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Courrier d'identification de ligne lcd</td>
            <td>
              <a :href="decompte.courrier_indication_ligne_lcd"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Courrier d'invitation sn</td>
            <td>
              <a :href="decompte.courrier_invitation_sn"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
          <tr>
            <td scope="row">Demande de proposition de prix</td>
            <td>
              <a :href="decompte.demande_proposition_prix"
                 target="_blank"
                 download>Télécharger
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-uppercase"
                id="exampleModalLabel">justifiez la demande</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <textarea class="form-control"
                          name="demande_info"
                          id=""
                          placeholder="Votre justification"
                          rows="3"
                          v-model="form.demande_information"> </textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">&#10060; Annuler</button>
            <button type="button"
                    @click="faireUneDemandeInfo('DEMANDE_INFORMATION')"
                    class="btn btn-primary">Envoyer</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade"
         id="exampleModal1"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"
                id="exampleModalLabel">Conformité du décompte</h4>
            <button type="button"
                    class="close"
                    ref="close_conf"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="mt-5 text-center">VEUILLEZ VOUS ASSURER DE LA CONFORMITE DES ELEMENTS SUIVANTS:</p>
            <form>
              <template v-for="(conf,cokey) in conformite">
                <div class="form-row"
                     v-if="(decompte.type_decompte=='decompte_travaux_ageroute' || decompte.type_decompte=='decompte_travaux_fer' || decompte.type_decompte=='decompte_travaux_dgir') 
                       &&(conf.designation!='Feuille de temps' && conf.designation!='Rapport')"
                     :key="cokey">
                  <div class="form-group col-md-4">
                    <label for="">{{conf.designation}}</label>
                    <select class="form-control"
                            name="" 
                            id=""
                            v-model="conf.conformite">
                      <option value="">Conformité</option>
                      <option value="oui">Oui</option>
                      <option value="non">Non</option>
                    </select>
                  </div>
                  <div class="form-group col-md-8 py-2">
                    <textarea class="form-control"
                              name="commentaire"
                              id=""
                              placeholder="Votre justification"
                              v-model="conf.commentaire"
                              rows="2"> </textarea>
                  </div>
                </div>
                <div class="form-row"
                     v-else-if="(decompte.type_decompte=='decompte_intellectuel_ageroute') 
                       && (conf.designation=='Feuille de temps' || conf.designation=='Rapport' || conf.designation=='Decompte' 
                         || conf.designation=='Facture' || conf.designation=='Certificat de service fait')"
                     :key="cokey">
                  <div class="form-group col-md-4">
                    <label for="">{{conf.designation}}</label>
                    <select class="form-control"
                            name="" 
                            id=""
                            v-model="conf.conformite">
                      <option value="">Conformité</option>
                      <option value="oui">Oui</option>
                      <option value="non">Non</option>
                    </select>
                  </div>
                  <div class="form-group col-md-8 py-2">
                    <textarea class="form-control"
                              name="commentaire"
                              id=""
                              placeholder="Votre justification"
                              v-model="conf.commentaire"
                              rows="2"> </textarea>
                  </div>
                </div>
              </template>
              
              <!-- <div class="form-group">
                <textarea class="form-control"
                          name="demande_info"
                          id=""
                          placeholder="Votre justification"
                          rows="3"
                          v-model="form.demande_information"
                          required> </textarea>
              </div> -->
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="btn-close"
                    class="btn btn-secondary">&#10060; Annuler</button>
            <button type="button"
                    @click="checkConformite(),non_conforme=true"
                    class="btn btn-primary">&#9989; Refuser</button>
            <button type="button"
                    @click="checkConformite(),non_conforme=false"
                    class="btn btn-primary">&#9989; Valider</button>

          </div>
        </div>
      </div>
    </div>
    <!--Modal pour la suppression d'un décompte -->
    <div class="modal fade" 
         id="suppressionModal" 
         tabindex="-1" 
         role="dialog" 
         aria-labelledby="suppressionModalLabel"
         aria-hidden="true">

      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"
                id="suppressionModalLabel">Suppression de decompte</h4>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <p class="text-danger">Voulez vous vraiment supprimer ce decompte ?</p>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-success"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    @click="SupprimerDecompte(decompte.numero_decompte)"
                    class="btn btn-danger">Supprimer</button>
          </div>
        </div>

      </div>

    </div>

    <!--Modal pour l'imputation d'un décompte -->
    <div class="modal fade" 
         id="imputationModal" 
         tabindex="-1" 
         role="dialog" 
         aria-labelledby="imputationModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"
                id="imputationModalLabel">Imputation du décompte</h4>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="">Chargé d'opération</label>
                  <select class="form-control" 
                          name="" 
                          id=""
                          v-model="assigne[0]">
                    <option value="">Choisissez un chargé d'opération</option>
                    <option v-for="(charge_op,copkey) in charge_operation"
                            :key="copkey"
                            :value="charge_op.id">{{charge_op.email}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4"
                     v-if="userProfil=='2'|| userProfil=='3'|| userProfil=='4'">
                  <label for="">Chef de service</label>
                  <select class="form-control" 
                          name="" 
                          id=""
                          v-model="assigne[1]">
                    <option value="">Choisissez un chef de service</option>
                    <option v-for="(chef_serv,cserkey) in chef_service"
                            :key="cserkey"
                            :value="chef_serv.id">{{chef_serv.email}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4"
                     v-if="userProfil=='3'||userProfil=='4'">
                  <label for="">Chef de département</label>
                  <select class="form-control" 
                          name="" 
                          id=""
                          v-model="assigne[2]">
                    <option value="">Choisissez un chef de département</option>
                    <option v-for="(chef_dep,cdekey) in chef_departement"
                            :key="cdekey"
                            :value="chef_dep.id">{{chef_dep.email}}</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    @click="imputerdecompte()"
                    class="btn btn-f-blue">Imputer</button>
          </div>
        </div>

      </div>

    </div>
    <!--Modal pour la soumission d'un décompte -->
    <div class="modal fade" 
         id="soumissionModal" 
         tabindex="-1" 
         role="dialog" 
         aria-labelledby="soumissionModalLabel"
         aria-hidden="true">

      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"
                id="soumissionModalLabel">Soumission pour validation du décompte</h4>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="">Recepteur</label>
                  <select class="form-control" 
                          name="" 
                          id=""
                          v-model="soumis">
                    <option value="">Choisissez qui recevra le décompte</option>
                    <option v-for="(soumiss,soukey) in listSoumis"
                            :key="soukey"
                            :value="soumiss.id">{{soumiss.email}}</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    @click="validerdecompte()"
                    class="btn btn-f-blue">Soumettre</button>
          </div>
        </div>

      </div>

    </div>
    <!--Le tableau des commentaires -->
    <div class="row mt-3 mx-md-5"
         id="tabCommentaire"
         v-if="viewTabCommentaire === 'true'">
      <h3>Les commentaires par date de création</h3>
      <table class="table table-hover"
             v-if="gettableauCommentaires.donnees.length > 0">
        <thead>
          <tr scope="col"
              class="bg-black">
            <th>Origine</th>
            <th>Email</th>
            <th>Contenu</th>
            <th>Date création</th>
          </tr>
        </thead>
        <tbody>
          <tr scope="row"
              v-for="(commentaire, commetaireKey) in gettableauCommentaires.donnees"
              :key="commetaireKey">
            <td>{{ commentaire.utilisateur.type_utilisateur }}</td>
            <td>{{ commentaire.utilisateur.email }}</td>
            <td>{{ commentaire.text }}</td>
            <td>{{ createDateFromString(commentaire.created_at) }}</td>
          </tr>
        </tbody>
      </table>
      
      <table class="table table-hover"
             v-else>
        <thead>
          <tr>
            <th class="text-center">Aucun commentaire trouvé!</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="row">
      <div class="col-md-12"
           v-if="marche!=''">
        
      </div>
    </div>
    <!-- formulaire de création de décaissement -->
    <div class="modal fade col-md-12"
         id="ajoutModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="ajoutModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="ajoutModalLabel">Créer un décaissement</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="form">
              
              <div class="form-group">
                <label for="">Type de paiement</label>
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="formDecaissement.type_paiement">
                  <option value="">---Veuillez choisir un type de paiement---</option>
                  <option v-if="Number(restePayer) == Number(decompte.montant_facture_decompte)" 
                          value="entier">Paiement entier</option>
                  <option value="partiel">Paiement partiel</option>
                </select>
              </div>
              <div class="form-group"
                   v-if="formDecaissement.type_paiement!='entier'">
                <label for="">Motif</label>
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="formDecaissement.motif">
                  <option value="">---Veuillez choisir un motif---</option>
                  <option value="fond_insuffisant">Défaut de trésorerie</option>
                  <option value="reserve">Réserve</option>
                </select>
              </div>
              <div class="form-group"
                   v-if="formDecaissement.type_paiement!='entier'">
                <label for="">Montant</label>
                <input type="text"
                       class="form-control"
                       @input="addSpace('montant')"
                       v-model="montant">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="createDecaissement()">Créer</button>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1170"
                  :filename="'Fiche_analyse_decompte_'+decompte.numero_decompte"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="827px"
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf">
      <div slot="pdf-content">
        <FicheAnalyse :extDecompte="decompte"
                      :extMarche="marche"
                      :extConformite="listConformiteDecompte"
                      v-if="marche.id"/>
      </div>
      
    </vue-html2pdf>
  </div>
</template>
<style scoped>
.telechargement{

  width: 70%;
}
</style>

<script>

import Notif from "@/components/shared/Toast"
import FicheAnalyse from "@/components/shared/FicheAnalyse"
import { mapActions, mapGetters, mapMutations } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'
import utils from "@/assets/js/utils"
import form from "vuejs-form"
export default {
  name: "dcmpDetailFer",
  components: {
    Notif,
    FicheAnalyse,
    VueHtml2pdf
  },
  data: () => ({
    decompte: "",
    viewTabCommentaire: "false",
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    assigne:[
      "",
      "",
      ""
    ],
    charge_operation:[],
    chef_service:[],
    chef_departement:[],
    user: "",
    reponse: "",
    conformite:[
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Assurance tout risque",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Caution d'avance de démarrage",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Retenue de garantie",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Cautionnement définitif",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Attachement",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Décompte",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Procès verbal de reception des travaux",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Certificat de service fait",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Facture",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Feuille de temps",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Rapport",conformite:"",commentaire:""},
      {decompte:"",utilisateur:localStorage.getItem("user").id,type_decompte:"",designation:"Situation financière marché",conformite:"",commentaire:""},
    ],
    form: {
      statut_validation: "",
      demande_information: "",
    },
    formDecaissement:form({
      id_decompte:"",
      type_paiement:"",
      motif:"",
      montant:""
    }),
    montant:"0",
    posttext: {
      text: ""
    },
    hider:true,
    non_conforme:false,
    soumis:"",
    listSoumis:[],
    last:false,
    status_valider: true,
    userRole: "",
    userProfil:"",
    niveau:"",
    nom_bouton:"Traiter"
  }),
  watch: {
    assignDecompte() {
      if (this.assignDecompte) {
        this.decompte = this.assignDecompte
        this.getMarche(this.decompte.marche.id)
        // console.log(this.decompte)
        if (this.decompte.dernier_valideur!=null) {
          this.niveau= this.decompte.dernier_valideur.split("_")
        }
        const user= JSON.parse(localStorage.getItem("user"))
        
        this.conformite.forEach(element => {
          element.utilisateur= user.user_id
          element.decompte= this.decompte.id
          element.type_decompte= this.decompte.type_decompte
        })
        console.log(this.conformite)

      }
    },
    listConformiteDecompte() {
      if (this.listConformiteDecompte.donnees.length>0) {
        for (let index = 0; index < this.listConformiteDecompte.donnees.length; index++) {
          for (let i = 0; i < this.conformite.length; i++) {
            if (this.conformite[i].designation==this.listConformiteDecompte.donnees[index].designation) {
              this.conformite[i].conformite=this.listConformiteDecompte.donnees[index].conformite
              this.conformite[i].commentaire=this.listConformiteDecompte.donnees[index].commentaire
              this.conformite[i].type_decompte=this.listConformiteDecompte.donnees[index].type_decompte
            }
          }
        }
      }
    },
    marche(){
      if (this.marche) {        
        var nouveau_departement=""
        for (let index = 0; index < this.marche.departement.length; index++) {
          if (index<this.marche.departement.length && index+1<this.marche.departement.length) {
            nouveau_departement+=this.marche.departement[index].libelle+" - "    
          }else{
            nouveau_departement+=this.marche.departement[index].libelle    
          }
        }
        this.marche.departement=nouveau_departement
      }
    },
    listUsers(){
      if (this.listUsers.donnees) {
        console.log(this.listUsers.donnees)
        this.listSoumis=[]
        // const userOn= JSON.parse(localStorage.getItem("user"))
        // var level= userOn.user_profil.split("_")
        // var niveau=""
        this.listUsers.donnees.forEach(user => {
          console.log(user.type)          
          if(user.type==this.userRole){
            if (user.profil_metier=="niveau_1") {
              this.charge_operation.push(user)
            }else if(user.profil_metier=="niveau_2"){
              this.chef_service.push(user)
            }else if (user.profil_metier=="niveau_3") {
              this.chef_departement.push(user) 
            }
            if (user.profil_metier!=null) {
              // niveau = user.profil_metier.split("_")
              // if (Number(niveau[1]) >= Number(level[1])) {
              this.listSoumis.push(user)
              // }
            }  
          }
        })
        
      }
    },
    successdecompte(){
      if (this.successdecompte) {
        if(this.last){
          this.notif.message = "L'operation a été effectuée"
          this.notif.type = "toast-success"
          this.notif.activated = "activated"
          this.notif.type = "toast-success"
          this.notif.activated = "activated"
          // setTimeout(
          //   function() {
          //     this.notif.activated = ""
          //     this.$refs["btn-soumission"].click()
          //   }.bind(this),
          //   3000
          // )
          setTimeout(
            function() {
              this.notif.activated = ""
              location.reload()
            }.bind(this),
            3000
          )
        }
      }
    },
    conformiteDecompte(){
      if (this.conformiteDecompte) {
        if(this.last){
          if (this.non_conforme) {
            this.faireUneDemandeInfo("DEMANDE_INFORMATION")
            var commentaire={ data: "", id: this.$route.params.id }
            this.conformite.forEach(conforme => {
              if (conforme.commentaire!="" && conforme.commentaire!=null) {
                commentaire.data+=conforme.commentaire+"; \n"
              }
            })
            console.log(commentaire)
            this.actionPostCommentaire({data:{text:commentaire.data},id:commentaire.id})
          } else {
            this.faireUneDemandeInfo("VALIDE")
          }
          this.notif.message = "L'operation a été effectuée"
          this.notif.type = "toast-success"
          this.notif.activated = "activated"
          console.log(this.userProfil)
          if (this.userProfil != '4') {
            setTimeout(
              function() {
                this.notif.activated = ""
                this.$refs["btn-soumission"].click()
                this.$refs["close_conf"].click()
                for (let index = 0; index < this.conformite.length; index++) {
                  this.conformite[index].conformite=""
                }
              }.bind(this),
              3000
            )
          }else{
            setTimeout(
              function() {
                this.notif.activated = ""
                location.reload()
              }.bind(this),
              3000
            )
          }
        }
      }
    },
    msgSuccessDecaissement(){
      if (this.msgSuccessDecaissement) {
        this.notif.message = this.msgSuccessDecaissement
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.set
            this.formDecaissement=form({
              id_decompte:"",
              type_paiement:"",
              motif:"",
              montant:""
            }),
            location.reload()
            this.montant="0"
          }.bind(this),
          3000
        )
      }
    },
    soumissionDecompte(){
      if (this.soumissionDecompte) {
        this.notif.message = "Décompte transmis"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.set
            this.formDecaissement=form({
              id_decompte:"",
              type_paiement:"",
              motif:"",
              montant:""
            }),
            location.reload()
            this.montant="0"
          }.bind(this),
          3000
        )
      }
    },
    msgFailDecaissement(){
      if (this.msgFailDecaissement) {
        this.notif.message = this.msgFailDecaissement
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    
    updateDecomptemsg() {
      if (this.updateDecomptemsg ==201) {
        this.reponseMessage="ok"

        this.$refs["btn-close"].click()
        this.notif.message = "La mise à jour a bien été effectuée"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            // this.$router.push({ name: "DecompteValider" })
          }.bind(this),
          10000
        )
      } else {
        this.$refs["btn-close"].click()

        this.notif.message = "La mise a jour n'a pas aboutit"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          10000
        )
      }
    }
  },
  created() {
    this.getDecompte(this.$route.params.id)
    this.resteAPayer(this.$route.params.id)
    this.getConformitesDecomptes(this.$route.params.id)
    this.formDecaissement.id_decompte = this.$route.params.id
    this.actionGetCommentaires(this.$route.params.id)
    this.userRole = utils.getUserRole()
    console.log(this.userRole)
    this.userProfil = utils.getUserProfil()
    switch (this.userProfil) {
      case "2":
        this.nom_bouton="Vérifier"
        break
      case "3":
        this.nom_bouton="Approuver"
        break
      case "4":
        this.nom_bouton="Valider"
        break
      default:
        this.nom_bouton="Traiter"
        break
    }
    var filtre =[]
    filtre.push({
      libelle:"type_utilisateur",
      nature:"egale",
      type:"caractere",
      table:"utilisateurs",
      valeur:this.userRole
    }) 
    this.getListUsers(filtre)
    console.log(this.userProfil)
  },
  mounted() {},
  computed: {
    ...mapGetters(["assignDecompte", "decerrors", "listUsers","successdecompte","decompterrors", "gettableauCommentaires","msgSuccessDecaissement","msgFailDecaissement","restePayer","msgFailRestePayer","marche","conformiteDecompte","soumissionDecompte","listConformiteDecompte","MsgFailListConformiteDecompte",])
  },
  methods: {
    ...mapActions(["getDecompte", "actionGetCommentaires", "getListUsers", "actionPostCommentaire", "actionValidateDecompte","imputerDecompte","saveDecaissements","resteAPayer","getMarche","conformitesDecomptes","getConformitesDecomptes","soumettreDecompte"]),
    ...mapMutations(["setError", "setSuccess","setMsgSuccessDecaissement","setMsgFailDecaissement","setRestePayer","setMsgFailRestePayer","setConformiteDecompte","setMsgFailConformiteDecompte","setListConformiteDecompte","setMsgFailListConformiteDecompte","setSoumissionDecompte","setMsgFailSoumissionDecompte"]),
    launchUpdate(id) {
      this.$router.push({ name: "Modification", params: { id: id } })
    },
    readFile(url) {
      window.open(url, "_blank")
    },
    //Functions added by Barry Alpha
    /**
     *
     */
    goBack() {
      this.$router.push({ name: "DecompteValider" })
    },
    /**
     *
     * @param chaine
     * @returns {string}
     */
    createDateFromString(chaine) {
      var tab = chaine.split(":")
      var dateheure = tab[0].split("T")
      var date = dateheure[0]
      var heureminute = dateheure[1] + "h" + tab[1]
      return date + " à " + heureminute
    },
    /**
     *
     */
    displayTabCommentaire() {
      this.viewTabCommentaire = "true"
    },
    /**
     *
     */
    displayNoneTabCommentaire() {
      this.viewTabCommentaire = "false"
    },
    /**
     * La fonction permet d'envoyer la mise a jour du decompte valider ou refuser
     * Ensuite elle envoi le commentaire lié a l'action precedente
     * @param demande c'est le commentaire lié a l'action
     */
    faireUneDemandeInfo(demande) {
      this.posttext.text = this.form.demande_information
      this.form.statut_validation = demande
      this.actionValidateDecompte({ data: this.form, id: this.decompte.id })
      //A ce niveau je devrais pouvoir tester la valeur de updateDecomptemsg mais apres
      //l'envoie de la requete pour valider le decompte, on a rien
      if (this.updateDecomptemsg !== "") {
        this.posttext.text = this.form.demande_information
        // this.actionPostCommentaire({ data: this.posttext, id: this.$route.params.id })
        this.notif.message = "La mise à jour a été effectuée!"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            // location.reload()
          }.bind(this),3000)
      }
      else {
        this.notif.message = "Une erreur s'est produite!"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            location.reload()
          }.bind(this),3000)
      }
    },
    /**
     * Pour la suppression d'un decompte dont l'id est donnée en argments
     * @param decompteId
     * @constructor
     */
    SupprimerDecompte(decompteId){
      //TODO integrer l'endpoint une fois disponible pour supprimer un decompte
      //this.notif.message = "Le decompte avec id = "+decompteId+" a été bien supprimé"
      this.notif.message="La suppression de decompte ("+decompteId+") n'est encore mise en place!! Nous travaillons dessus. Merci"
      this.notif.type = "toast-success"
      this.notif.activated = "activated"

      setTimeout(
        function() {
          this.notif.activated = ""
          location.reload()
          this.$router.push({name:"DecompteValider"})
        }.bind(this),3000)
    },
    updateDecompte(){
      this.$router.push({name:"UpdateTravauxFER",params: {id:this.$route.params.id}})
    },
    recreate(value,key){
      var splinter = value.split(" ")
      this.formDecaissement[key] = ""
      for (let index = 0; index < splinter.length; index++) {
        this.formDecaissement[key] += splinter[index]
      }
    },

    checkConformite(){
      this.last=false
      // this.non_conforme=false
      for (let index = 0; index < this.conformite.length; index++) {
        if(this.conformite[index]!=""&&this.conformite[index]!=0){
          // if (this.conformite[index].conformite=="non") {
          //   this.non_conforme=true
          // }
          this.conformitesDecomptes({
            decompte:this.conformite[index].decompte,
            utilisateur:this.conformite[index].utilisateur,
            type_decompte:this.conformite[index].type_decompte,
            designation:this.conformite[index].designation,
            conformite:this.conformite[index].conformite,
            commentaire:this.conformite[index].commentaire
          })
        }
        if(index==this.conformite.length-1){
          this.last=true
        }
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        this.form[key] = this[key]
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }

      this[key] = chaine
    },
    createDecaissement() {
      this.recreate(this.montant, 'montant')
      this.saveDecaissements(this.formDecaissement.data)
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    imputerdecompte(){
      this.last=false
      for (let index = 0; index < this.assigne.length; index++) {
        if(this.assigne[index]!=""&&this.assigne[index]!=0){
          this.imputerDecompte({"recepteur":this.assigne[index],"decompte":this.$route.params.id})
        }
        if(index==this.assigne.length-1){
          this.last=true
        }
      }
    },
    validerdecompte(){
      const user= JSON.parse(localStorage.getItem("user"))
      this.soumettreDecompte({"emetteur":user.user_id,"recepteur":this.soumis,"decompte":this.$route.params.id})
      
    }
  }
}
</script>
