<template>
  <div style="width: 90%;padding-left: 6%;padding-top: 10%;">
    <table class="fiche"
           v-if="decompte.type_decompte=='decompte_travaux_fer'
             || decompte.type_decompte=='decompte_travaux_dgir'
             || decompte.type_decompte=='decompte_travaux_ageroute'">
      <tbody>
        <tr style="border-bottom: 1px solid white;">
          <td rowspan="2"
              style="width: 25%;text-align: center;"
              class="td-logo">
            <img src="@/assets/logo.png" 
                 alt="Logo FER">
          </td>
          <td style="width: 50%;vertical-align: middle;text-align: center;">GERER LES PROJETS ROUTIERS</td>
          <td rowspan="2"
              style="width: 25%;">
            Code: <br/>
            Rédigé: <br/>
            Révisé: <br/>
            Version: <br/>
          </td>
        </tr>
        <tr v-if="decompte!=''&& (decompte.type_decompte=='decompte_travaux_fer'||decompte.type_decompte=='decompte_travaux_ageroute')"
            style="border-top: 1px solid white;">
          <td style="width: 50%;vertical-align: middle;text-align: center;">FICHE D’ANALYSE DU DECOMPTE ENTREPRISE DE TRAVAUX</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="vertical-align: middle;text-align: center;"
              colspan="3">FICHE D’ANALYSE DU DECOMPTE N°{{ decompte.numero_decompte }}</td>
        </tr>
      </tbody>
      
    </table>
    <table class="fiche"
           v-if="decompte.type_decompte=='decompte_travaux_fer'
             || decompte.type_decompte=='decompte_travaux_dgir'
             || decompte.type_decompte=='decompte_travaux_ageroute'">
      <tbody>
        <tr>
          <td style="width: 33%;text-align: center;">
            DIRECTION TECHNIQUE
          </td>
          <td style="width: 34%;">
            <strong>Délai d'exécution: {{ inMarche.delai_execution_marche }}</strong> mois <br/>
            <strong>OS démarrage:</strong> {{ inMarche.reference_ordre_service_demarrage }}<br/>
            <strong>Démarrage effectif des travaux:</strong> {{ inMarche.date_demarrage_effectif }}<br/>
            <strong>OS prorogation de délai:</strong> {{ decompte.reference_ordre_service_prorogation_delai }}<br/>
            <strong>OS modification d'itinéraire:</strong> {{ decompte.reference_ordre_service_modification_itineraire }}<br/>
          </td>
          <td style="width: 33%;text-align: center;">
            {{inMarche.activite.rubrique.programme.libelle}} <br/>
            {{inMarche.type_ent}} <br/>
            {{inMarche.activite.rubrique.libelle}} <br/>
            {{inMarche.activite.libelle}} <br/>
            {{inMarche.departement}} <br/>
          </td>
        </tr>
        
      </tbody>
      <tbody>
        <tr>
          <td style="vertical-align: middle;"
              colspan="3">OBJET: {{ inMarche.objet }}</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="width: 33%;">
            Marché n°: {{ decompte.marche.numero_marche }}<br/>
            Approuvé le {{ marche.date_approbation_marche }}
          </td>
          <td style="width: 34%;text-align: center;">
            <strong>Attestation de réservation de crédit</strong> <br/>
            {{ decompte.marche.reference_arc }}<br/>
            <strong>Accord de financement</strong> <br/>
            {{ decompte.marche.reference_af }}<br/>
            <br/>
          </td>
          <td style="width: 33%;">
            Montant total: <strong><span v-if="inMarche.montant_total">{{ Math.round(inMarche.montant_total).toLocaleString() }}</span></strong> FCFA TTC<br/><br>
            <template v-if="inMarche.ordonnancements.length>1">
              <p v-for="(ordonnancement,okey) in inMarche.ordonnancements"
                 :key="okey"> 
                <template v-if="ordonnancement.ordonnancement_created_at!=null">
                  Ordonnancement <br/>
                  <strong>Année {{ ordonnancement.annee_ordonnancement }} : {{ Math.round(ordonnancement.montant_ordonnancement).toLocaleString() }} FCFA TTC</strong> 
                </template>
              </p>
            </template>
            <!-- <template v-else>
              Ordonnancement {{ inMarche.ordonnancements[0].annee_ordonnancement }}<br/>
              <strong>Année : {{ inMarche.ordonnancements[0].montant_ordonnancement }} FCFA</strong><br/>
            </template> -->
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="vertical-align: middle;text-align: center;background-color: rgb(173, 173, 173);"
              colspan="3">LISTE DES DOCUMENTS TRANSMIS ET OBSERVATIONS:</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="width: 33%;">
            Assurance tout risque 
          </td>
          <td style="width: 34%;">
            <strong>({{ decompte.libelle_assurance_chantier }}) : {{ decompte.montant_assurance_chantier }}</strong> FCFA<br/>
            <br/>
            <strong>Etablie le</strong> {{ decompte.date_assurance_chantier }}<br/>
            <strong>Expire le</strong> {{ decompte.expiration_assurance_chantier_texte ==""?decompte.expiration_assurance_chantier_date:decompte.expiration_assurance_chantier_texte }}<br/>
            <br/>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Assurance tout risque'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
          
        </tr>
        <tr>
          <td style="width: 33%;">
            Caution d'avance de démarrage 
          </td>
          <td style="width: 34%;">
            <strong>({{ decompte.libelle_cautionnement_ad }}) : {{ decompte.montant_cautionnement_ad }}</strong> FCFA<br/>
            <br/>
            <strong>Etablie le</strong> {{ decompte.date_etablissement_cautionnement_ad }}<br/>
            <strong>Expire le</strong> {{ decompte.expiration_caution_ad_texte==""?decompte.expiration_caution_ad_date:decompte.expiration_caution_ad_texte }}<br/>
            <br/>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Caution d\'avance de démarrage'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Retenue de garantie (5%) <br>
            (Garantie de bonne exécution) 
          </td>
          <td style="width: 34%;"
              v-if="decompte.libelle_cautionnement_rg!='PRELEVEMENT'">
            <strong>({{ decompte.libelle_cautionnement_rg }}) : {{ decompte.montant_cautionnement_rg }}</strong> FCFA<br/>
            <br/>
            <strong>Etablie le</strong> {{ decompte.date_etablissement_cautionnement_rg }}<br/>
            <strong>Expire le</strong> {{ decompte.expiration_caution_rg_texte==""?decompte.expiration_caution_rg_date:decompte.expiration_caution_rg_texte }}<br/>
            <br/>
          </td>
          <td style="width: 34%;"
              v-else>
            Une retenue de garantie de  
            {{ Math.round((decompte.montant_cautionnement_rg/decompte.montant_facture_decompte)*100) }}% 
            sera prélevée sur le montant de chaque décompte
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Retenue de garantie'">
              Conformité 
              {{decompte.libelle_cautionnement_rg=="PRELEVEMENT"?"":"(signatures, date, cachets, fonctions des signataires)"}} 
              : {{ conformite.conformite }}{{decompte.libelle_cautionnement_rg=="PRELEVEMENT"
                ?", le montant de"
                  +Math.round(decompte.montant_facture_decompte).toLocaleString()+
                  " FCFA HT a été prélevé du décompte N°"+decompte.numero_decompte:""  }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Cautionnement définitif (3%) <br>
            (Garantie de bonne exécution) 
          </td>
          <td style="width: 34%;">
            <strong>({{ decompte.libelle_cautionnement_definitif }}) : {{ decompte.montant_cautionnement_definitif }}</strong> FCFA<br/>
            <br/>
            <strong>Etablie le</strong> {{ decompte.date_etablissement_cautionnement_definitif }}<br/>
            <strong>Expire le</strong> {{ decompte.expiration_cautionnement_definitif_date}}<br/>
            <br/>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Cautionnement définitif'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Attachement N° 
          </td>
          <td style="width: 34%;">
            <ul>
              <li>Dressé le {{decompte.date_attachement_constat_travaux}} par la mission de contrôle 
                {{inMarche.mission_suivi_controle==null?inMarche.mission_suivi_controle_historique:inMarche.mission_suivi_controle}}</li>
              <li>Visé le {{ decompte.date_visa_attachement }} par l'Entreprise</li>
              <li>Validé le {{ decompte.date_validation_attachement }} par ...</li>
            </ul>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Attachement'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Décompte N° {{ decompte.numero_decompte }}
          </td>
          <td style="width: 34%;">
            <ul>
              <li>Dressé le {{decompte.date_dressage_decompte}} par le Chef de mission de ...</li>
              <li>Visé le {{decompte.date_visa_dr_decompte}} par la DR/AGEROUTE</li>
              <li>Visé le {{decompte.date_visa_dmc_decompte}} par le DMC/AGEROUTE</li>
              <li>Visé le {{decompte.date_visa_dga_decompte}} par le DGA/AGEROUTE</li>
              <li>Transmis au FER le {{decompte.date_transmission_decompte}} par l'AGEROUTE</li>
              <li>Reçu à la DT/FER le {{decompte.date_transmission_decompte}}</li>
              <li>Montant: <strong>{{Math.round(decompte.montant_decompte).toLocaleString()}} FCFA TTC</strong></li>

            </ul>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Décompte'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Procès-verbal de réception  des travaux 
          </td>
          <td style="width: 34%;">
            <ul>
              <li>Existence </li>
              <li>Etablie le {{decompte.date_etablissement_proces_verbal_reception_travaux}} </li>
            </ul>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Procès verbal de reception des travaux'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Certificat de service fait 
          </td>
          <td style="width: 34%;">
            <ul>
              <li>N° ...</li>
              <li>Etablie le :</li>
            </ul>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Certificat de service fait'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Situation financière du marché 
          </td>
          <td style="width: 34%;">
            Solde sur le marché (après prise en compte du décompte):{{ Number(decompte.situation_financiere_marche).toLocaleString() }} FCFA TTC
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Situation financière marché'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            FACTURE N° {{ decompte.reference_facture }}
          </td>
          <td style="width: 34%;">
            <ul>
              <li>Montant: {{Math.round(decompte.montant_decompte).toLocaleString()}} FCFA TTC</li>
              <li>Etablie le : {{ decompte.date_facture }}</li>
            </ul>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Facture'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td colspan="3">
            Observation: <br>
            <template v-for="(conformite,conkey) in inConformite.donnees">
              <span :key="conkey"
                    v-if="conformite.commentaire!=''&&conformite.commentaire!=null">
                - {{ conformite.commentaire }}; <br> 
              </span>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            AVIS: 
            <strong v-if="listUtilisateurs.length > 0">
              {{
                listUtilisateurs[listUtilisateurs.length-1].avis=="VALIDE"?"CONFORME"
                :listUtilisateurs[listUtilisateurs.length-1].avis==""||listUtilisateurs[listUtilisateurs.length-1].avis==null?"":"NON CONFORME"
              }}
            </strong> 
          </td>
        </tr>
        <tr>
          <td style="width: 33%;">
            Réalisé par <strong class="text-uppercase"
                                v-if="listUtilisateurs.length > 0">{{listUtilisateurs[0].nom}}</strong> <br> le 
            <strong v-if="listUtilisateurs.length > 0">{{listUtilisateurs[0].date}}</strong> 
          </td>
          <td style="width: 34%;">
            Vérifié par <strong class="text-uppercase"
                                v-if="listUtilisateurs.length > 1">{{listUtilisateurs[1].nom}}</strong> <br> le 
            <strong v-if="listUtilisateurs.length > 1">{{listUtilisateurs[1].date}}</strong>
          </td>
          <td style="width: 33%;">
            Visé par <strong class="text-uppercase"
                             v-if="listUtilisateurs.length > 2">{{listUtilisateurs[2].nom}}</strong> <br> le 
            <strong v-if="listUtilisateurs.length > 2">{{listUtilisateurs[2].date}}</strong>
          </td>
        </tr>
      </tbody>
    </table> 
    <!-- FICHE decompte_prestation intellectuelle  -->
    <table class="fiche"
           v-if="decompte.type_decompte=='decompte_intellectuel_dgir'
             || decompte.type_decompte=='decompte_intellectuel_ageroute'">
      <tbody>
        <tr style="border-bottom: 1px solid white;">
          <td rowspan="2"
              style="width: 25%;text-align: center;"
              class="td-logo">
            <img src="@/assets/logo.png" 
                 alt="Logo FER">
          </td>
          <td style="width: 50%;vertical-align: middle;text-align: center;">GERER LES PROJETS ROUTIERS</td>
          <td rowspan="2"
              style="width: 25%;">
            Code: <br/>
            Rédigé: <br/>
            Révisé: <br/>
            Version: <br/>
          </td>
        </tr>
        <tr style="border-top: 1px solid white;">
          <td style="width: 50%;vertical-align: middle;text-align: center;">FICHE D’ANALYSE DU DECOMPTE DE PRESTATION INTELLECTUELLE</td>
        </tr>
      </tbody>
    </table>
    <table class="fiche"
           v-if="decompte.type_decompte=='decompte_intellectuel_dgir'
             || decompte.type_decompte=='decompte_intellectuel_ageroute'">
      <tbody>
        <tr style="border-bottom: 1px solid white;">
          <td style="width: 33%;text-align: center;"
              rowspan="2">
            DIRECTION TECHNIQUE
          </td>
          <td style="width: 33%;text-align: center;">TITULAIRE <br></td>
          <td style="width: 33%;text-align: center;"
              rowspan="2">
            {{inMarche.activite.rubrique.programme.libelle}} <br/>
            {{inMarche.type_ent}} <br/>
            {{inMarche.activite.rubrique.libelle}} <br/>
            {{inMarche.activite.libelle}} <br/>
            {{inMarche.departement}} <br/>
          </td>
        </tr>
        <tr style="border-top: 1px solid white;">
          <td style="width: 34%;">
            <strong>Délai d'exécution: {{ inMarche.delai_execution_marche }}</strong> mois <br/>
            <strong>OS démarrage:</strong> {{ inMarche.reference_ordre_service_demarrage }}<br/>
            <strong>Démarrage effectif des travaux:</strong> {{ inMarche.date_demarrage_effectif }}<br/>
            <strong>OS prorogation de délai:</strong> {{ decompte.reference_ordre_service_prorogation_delai }}<br/>
            <strong>OS modification d'itinéraire:</strong> {{ decompte.reference_ordre_service_modification_itineraire }}<br/>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="vertical-align: middle;"
              colspan="3">OBJET: {{ inMarche.objet }}</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="width: 33%;">
            Contrat n°: {{ decompte.marche.numero_marche }}<br/>
            Approuvé le {{ decompte.date_approbation }}
          </td>
          <td style="width: 34%;text-align: center;">
            <strong>Attestation de réservation de crédit</strong> <br/>
            {{ decompte.marche.reference_arc }}<br/>
            <strong>Accord de financement</strong> <br/>
            {{ decompte.marche.reference_af }}<br/>
            <br/>
          </td>
          <td style="width: 33%;">
            Montant total: FCFA TTC<br/>
            <template v-if="inMarche.ordonnancements">
              <span v-for="(ordonnancement,okey) in inMarche.ordonnancements"
                    :key="okey"> Ordonnancement <br/>
                <strong>Année {{ ordonnancement.annee_ordonnancement }} : {{ Math.round(ordonnancement.montant_ordonnancement).toLocaleString() }} FCFA TTC</strong> </span><br/>
            </template>
            <template v-else>
              Ordonnancement {{ inMarche.ordonnancements[0].annee_ordonnancement }}<br/>
              <strong>Année : {{ inMarche.ordonnancements[0].montant_ordonnancement }} FCFA</strong><br/>
            </template>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="vertical-align: middle;text-align: center;background-color: rgb(173, 173, 173);"
              colspan="3">LISTE DES DOCUMENTS TRANSMIS ET OBSERVATIONS:</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td style="width: 33%;">
            Feuille de temps 
          </td>
          <td style="width: 34%;">
            <strong>Dressé le{{ decompte.date_etablissement_feuille_temps }}</strong> <br/>
            <strong>par</strong> {{ marche.entreprise.libelle ==""?marche.entreprise_historique:marche.entreprise.libelle }}<br/>
            <br/>
          </td>
          <td style="width: 33%;">
            Conformité (signatures) :
          </td>
        </tr>
        <tr>
          <td style="width: 33%;">
            Décompte N° {{ decompte.numero_decompte }}
          </td>
          <td style="width: 34%;">
            <ul>
              <li>Dressé le {{decompte.date_facture}} par le Chef de mission de ...</li>
              <li>Visé le {{decompte.date_visa_dr_decompte}} par la DR/AGEROUTE</li>
              <li>Visé le {{decompte.date_visa_dmc_decompte}} par le DMC/AGEROUTE</li>
              <li>Visé le {{decompte.date_visa_dga_decompte}} par le DGA/AGEROUTE</li>
              <li>Transmis au FER le {{decompte.date_transmission_decompte}} par l'AGEROUTE</li>
              <li>Reçu à la DT/FER le {{decompte.date_transmission_decompte}}</li>
              <li>Montant: <strong>{{Math.round(decompte.montant_decompte).toLocaleString()}} FCFA TTC</strong></li>

            </ul>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Feuille de temps'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Certificat de service fait 
          </td>
          <td style="width: 34%;">
            <ul>
              <li>N° {{decompte.reference_certificat_service_fait}}</li>
              <li>Etablie le : {{ decompte.date_etablissement_certificat_service_fait }}</li>
            </ul>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Certificat de service fait'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            Situation financière du marché 
          </td>
          <td style="width: 34%;">
            Solde sur le marché (après prise en compte du décompte): {{ Number(decompte.situation_financiere_marche).toLocaleString() }} FCFA TTC
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Situation financière marché'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td style="width: 33%;">
            FACTURE N° {{ decompte.reference_facture }}
          </td>
          <td style="width: 34%;">
            <ul>
              <li>Montant: {{Math.round(decompte.montant_decompte).toLocaleString()}} FCFA TTC</li>
              <li>Etablie le : {{ decompte.date_decompte }}</li>
            </ul>
          </td>
          <template v-for="(conformite,conkey) in inConformite.donnees">
            <td style="width: 33%;"
                :key="conkey"
                v-if="conformite.designation=='Facture'">
              Conformité (signatures, date, cachets, fonctions des signataires) : {{ conformite.conformite }}
            </td>
          </template>
        </tr>
        <tr>
          <td colspan="3">
            Observation: 
            <template v-for="(conformite,conkey) in inConformite.donnees">
              <span :key="conkey"
                    v-if="conformite.commentaire!=''&&conformite.commentaire!=null">
                - {{ conformite.commentaire }}; <br> 
              </span>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            AVIS: <strong 
              v-if="listUtilisateurs.length > 0">{{listUtilisateurs[listUtilisateurs.length-1].avis=="VALIDE"?"CONFORME":"NON CONFORME"}}</strong> 
          </td>
        </tr>
        <tr>
          <td style="width: 33%;">
            Réalisé par <strong class="text-uppercase"
                                v-if="listUtilisateurs.length > 0">{{listUtilisateurs[0].nom}}</strong> <br> le 
            <strong v-if="listUtilisateurs.length > 0">{{listUtilisateurs[0].date}}</strong> 
          </td>
          <td style="width: 34%;">
            Vérifié par <strong class="text-uppercase"
                                v-if="listUtilisateurs.length > 1">{{listUtilisateurs[1].nom}}</strong> <br> le 
            <strong v-if="listUtilisateurs.length > 1">{{listUtilisateurs[1].date}}</strong>
          </td>
          <td style="width: 33%;">
            Visé par <strong class="text-uppercase"
                             v-if="listUtilisateurs.length > 2">{{listUtilisateurs[2].nom}}</strong> <br> le 
            <strong v-if="listUtilisateurs.length > 2">{{listUtilisateurs[2].date}}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</template>
<style>
  table.fiche{
    font: bold 8px 'Century Gothic';
    width: 100%;
  }
  
  table.fiche tr,
  table.fiche tr td{
    border: 1px solid black;
  }
  table.fiche tr td{
    padding: 3px;
  }
  table.fiche td.td-logo img{
    height: 65px;
    width: 50%;
  }
</style>
<script>
import { mapActions, mapGetters} from "vuex"

export default{
  name:"FicheAnalyse",
  props:["extDecompte","extMarche","extConformite"],
  data:()=>({
    decompte:"",
    inMarche:"",
    inConformite:[],
    listUtilisateurs:[],
  }),
  watch:{
    
  },
  created(){
    this.decompte=this.extDecompte
    this.inMarche = this.extMarche
    this
    this.inConformite = this.extConformite
    this.listUtilisateurs=[]
    this.listUtilisateurs=this.inConformite.utilisateurs
    console.log(this.decompte)
    console.log(this.inMarche)
    console.log(this.listUtilisateurs)
    // if (this.inConformite.utilisateurs.length==1) {
    //   this.listUtilisateurs[0]=this.inConformite.donnees[0].utilisateur[0].nom
    // }else{
    //   if(this.inConformite.utilisateurs.length==2){
    //     this.listUtilisateurs[0]=this.inConformite.utilisateurs[0].nom
    //     if (this.inConformite.utilisateurs[1].type_utilisateur=="DT" 
    //       && this.inConformite.utilisateurs[1].profil_metier=="niveau_4") {
    //       this.listUtilisateurs[2]=this.inConformite.utilisateurs[1].nom
    //     }else{
    //       this.listUtilisateurs[1]=this.inConformite.utilisateurs[1].nom
    //     }
    //   }else{
    //     if (this.inConformite.utilisateurs.length > 2) {
    //       this.listUtilisateurs[0]=this.inConformite.utilisateurs[0].nom
    //       if (this.inConformite.donnees[0].utilisateur[0].departement=="DT" 
    //         && this.inConformite.donnees[0].utilisateur[0].fonction=="niveau_4") {
    //         this.listUtilisateurs[2]=this.inConformite.donnees[0].utilisateur[0].nom  
    //       }else{
    //         this.listUtilisateurs[1]=this.inConformite.donnees[0].utilisateur[0].nom  
    //       }
    //     }
    //   }
    // }
    // console.log(this.listUtilisateurs)
  },
  computed:{
    ...mapGetters(["marche",])
  },
  methods:{
    ...mapActions(["getMarche"]),
  }
}
</script>